<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="true" :before-close="handleClose">
		<div slot="title"></div>
		<img src="@/assets/noData.png" alt="">
		<div class="text">{{i18n('117')}}？</div>
		<div class="but">
			<div class="one" @click="handleClose">
				{{i18n('55')}}
			</div>
			<div class="two" @click="sub">
				{{i18n('56')}}
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "signOut",
		components: {

		},
		data() {
			return {
				show: false
			}
		},
		methods: {
			handleClose() {
				this.show = false
			},
			open() {
				this.show = true
			},
			sub(){
				let commodity = this.$store.state.cart.commodity
				let ids = []
				commodity.map(item => {
					if (item.checked) {
						ids.push(Number(item.CartId))
					}
				})
				this.$store.dispatch("cart/cartDelete", {
					ids
				})
				this.handleClose()
			}
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		padding: 0 58px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.but div{
		width: 160px;
		height: 44px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 24px;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		cursor: pointer;
	}
	.but div:nth-child(2){
		background: #56AB7B;
		color: #F5F5F5;
	}
	img{
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
		padding-top: 50px;
		padding-bottom: 10px;
	}
	.text{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 500;
		text-align: center;
		padding-bottom: 80px;
	}
	>>>.el-dialog__body {
		padding: 0;
	}

	>>>.el-dialog {
		width: 460px;
		height: 294px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	>>>.el-dialog__header {
		padding: 0;
	}
</style>
