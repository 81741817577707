<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				<div class="">
					{{i18n('109')}}（{{listData.length}}）
				</div>
				<div class="">
					{{i18n('110')}}
				</div>
			</div>
			<div class="titleRight" @click="close">
				<div class="">
					<img src="@/assets/close.png" alt="">
				</div>
			</div>
		</div>
		<div class="list">
			<div class="" style="height: 16px;width: 100%;">

			</div>
			<div class="noData" v-if="noData">
				<div class="">
					<img src="@/assets/noData2.png" alt="">
					<div class="text">
						{{i18n('111')}}
					</div>
				</div>
			</div>
			<div class="" style="margin-bottom: 16px;" v-for="(item,index) in listData" :key="index">
				<couponItem :type="0" :data='item'></couponItem>
			</div>
		</div>
		<div class="but" @click="close">
			{{i18n('112')}}
		</div>
	</el-dialog>
</template>

<script>
	import {
		couponOrderList
	} from "@/api/coupon.js"

	import couponItem from "@/components/couponItem"
	export default {
		name: "coupon",
		components: {
			couponItem
		},
		data() {
			return {
				show: false,
				params: {
					city_id: 0,
					cart_ids: [

					]
				},
				noData: false,
				listData: []
			}
		},
		methods: {
			async couponOrderList() {
				this.params.cart_ids = this.cart_ids
				let {
					data
				} = await couponOrderList(this.params)
				if (data.length != 0) {
					data.map((item => {
						let newDate = (new Date()).valueOf() / 1000;
						if (item.coupon.valid_type == 0) {
							item.yssj = parseInt((item.coupon.end_time - newDate) / 86400)
						} else {
							// 领取时间 + 有效时间-当前时间
							item.yssj = parseInt((item.history.create_time + item.coupon.valid_days -
								newDate) / 86400)
						}
					}))
					this.listData = data
					this.noData = false
				} else {
					this.noData = true
				}
				this.$emit('couponNumber', this.listData.length)
			},
			open() {
				if (this.$store.state.serviceArea.addressData) {
					this.listData = []
					this.couponOrderList()
					this.show = true
				} else {
					this.$message.error(this.i18n('113'))
				}
			},
			close() {
				this.show = false
			}
		},
		watch: {
			addressData() {
				this.params.city_id = this.$store.state.serviceArea.addressData.city_id
				this.couponOrderList()
			}
		},
		computed: {
			addressData() {
				let addressData = JSON.parse(JSON.stringify(this.$store.state.serviceArea.addressData))
				return addressData
			},
			cart_ids() {
				let xzData = []
				let forthwith = this.$store.state.cart.forthwith
				let reserve = this.$store.state.cart.reserve
				let totalPrice = 0
				forthwith.map(item => {
					if (item.checked) {
						xzData.push(Number(item.CartId))
						totalPrice = totalPrice + item.skuAc.price * item.Count
					}
				})
				reserve.map(item => {
					if (item.checked) {
						xzData.push(Number(item.CartId))
						totalPrice = totalPrice + item.skuAc.price * item.Count
					}
				})
				return xzData
			},
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		created() {

		},
		mounted() {
			if (this.$store.state.serviceArea.addressData) {
				this.params.city_id = this.$store.state.serviceArea.addressData.city_id
				this.couponOrderList()
			} else {
				this.$emit('couponNumber', 0)
			}
		}
	};
</script>

<style scoped>
	.noData{
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	.but {
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		margin-top: 16px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		text-align: center;
		line-height: 44px;
		cursor: pointer;
	}

	.list {
		height: 360px;
		overflow-y: scroll;
	}

	>>>.el-dialog__body {
		padding: 0 16px 24px 16px;
	}

	>>>.el-dialog {
		width: 460px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	>>>.el-dialog__header {
		padding: 0;
	}

	>>>.el-dialog__header div {
		padding: 0 16px;
		height: 72px;
		display: flex;
		justify-content: space-between;
		border: 0.5px solid rgba(0, 0, 0, .1);
	}

	>>>.el-dialog__header .titleLeft {
		display: block;
		height: 46px;
		border: none;
		padding: 0;
	}

	>>>.el-dialog__header .titleLeft div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 46px;
		font-weight: 500;
		border: none;
		display: block;
		padding: 0;
		height: 46px;
	}

	>>>.el-dialog__header .titleLeft div:nth-child(2) {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #56AB7B;
		font-weight: 400;
		border: none;
		display: block;
		padding: 0;
		height: auto;
	}

	>>>.el-dialog__header .titleRight {
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		padding: 0;
	}

	>>>.el-dialog__header .titleRight div {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		border: none;
		padding: 0;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>
