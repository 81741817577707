<template>
	<div class="cart">
		<tab @typeChange='typeChange' :type="type" v-if="commodity.length>0"></tab>
		<div class="inner" v-if="commodity.length>0">
			<div class="spXx" v-if="type==1">
				<div class="yhq">
					<div class="left">
						<img src="@/assets/yhq.png" alt="">
						{{i18n('22')}} （{{couponNumber}}{{i18n('120')}}）
					</div>
					<div class="right" @click="couponOpen">
						{{i18n('121')}}
					</div>
				</div>
				<div class="seleDz">
					<div class="left">
						<img src="@/assets/address.png" alt="">
						<div class="addressInfo">
							<div class="xx over">
								{{!addressData?i18n('122'):addressData.latitude==0?addressData.city+'-'+addressData.detail_address:addressData.detail_address+addressData.house_number}}
							</div>
							<div class="user">
								{{!addressData?i18n('123'):addressData.name}} <span
									v-if="addressData">{{addressData.phone}}</span>
							</div>
						</div>
					</div>
					<div class="right" @click="seleAddressOpen">
						{{i18n('124')}}
					</div>
				</div>
				<div class="jsps ps" v-if="forthwith.length>0">
					<div class="tit">
						<div class="spType">
							{{i18n('100')}}
						</div>
						（7AM-11:30PM）
					</div>
					<div class="spList">
						<div class="listHeader">
							<div class="sele" @click="checkedChange(-1,1)">
								<img src="@/assets/sele.png" alt="" v-if="forthwithChecked">
								<img src="@/assets/seleNo.png" alt="" v-else>
								{{i18n('125')}}
							</div>
							<div class="name">
								{{i18n('126')}}
							</div>
							<div class="dj">
								{{i18n('127')}}
							</div>
							<div class="sl">
								{{i18n('78')}}
							</div>
							<div class="xj">
								{{i18n('128')}}
							</div>
							<div class="cz">
								{{i18n('129')}}
							</div>
						</div>
						<div :class="jspsZd?'spListInner':'spListInner spListInnerAc'">
							<spItem v-for="(item,index) in forthwith" :key="index" :data='item'
								@checkedChange="checkedChange(index,1)"></spItem>
							<div class="zdTxt" v-if="forthwith.length>5" @click="jspsZd=!jspsZd">
								{{i18n('130')}}{{forthwith.length-5}} {{i18n('131')}}
								<img src="@/assets/view-list.png" alt="" v-if="jspsZd">
								<img src="@/assets/view-list-up.png" alt="" v-else>
							</div>
						</div>
					</div>
				</div>
				<div class="ydsp ps" v-if="reserve.length>0">
					<div class="tit">
						<div class="spType">
							{{i18n('85')}}
						</div>
					</div>
					<div class="spList">
						<div class="listHeader">
							<div class="sele" @click="checkedChange(-1,2)">
								<img src="@/assets/sele.png" alt="" v-if="reserveChecked">
								<img src="@/assets/seleNo.png" alt="" v-else>
								{{i18n('125')}}
							</div>
							<div class="name">
								{{i18n('126')}}
							</div>
							<div class="dj">
								{{i18n('127')}}
							</div>
							<div class="sl">
								{{i18n('78')}}
							</div>
							<div class="xj">
								{{i18n('128')}}
							</div>
							<div class="cz">
								{{i18n('129')}}
							</div>
						</div>
						<div :class="ydspZd?'spListInner':'spListInner spListInnerAc'">
							<spItem v-for="(item,index) in reserve" :key="index" :data='item'
								@checkedChange="checkedChange(index,2)"></spItem>
							<div class="zdTxt" v-if="reserve.length>5" @click="ydspZd=!ydspZd">
								{{i18n('130')}}{{reserve.length-5}}{{i18n('131')}}
								<img src="@/assets/view-list.png" alt="" v-if="ydspZd">
								<img src="@/assets/view-list-up.png" alt="" v-else>
							</div>
						</div>
					</div>
				</div>
				<div class="wxsp ps" v-if="invalid.length>0">
					<div class="tit">
						<div class="spType">
							{{i18n('132')}}
						</div>
					</div>
					<div class="spList">
						<div class="listHeader">
							<div class="sele" @click="checkedChange(-1,4)">
								<img src="@/assets/sele.png" alt="" v-if="invalidChecked">
								<img src="@/assets/seleNo.png" alt="" v-else>
								{{i18n('125')}}
							</div>
							<div class="name">
								{{i18n('126')}}
							</div>
							<div class="dj">
								{{i18n('127')}}
							</div>
							<div class="sl">
								{{i18n('78')}}
							</div>
							<div class="xj">
								{{i18n('128')}}
							</div>
							<div class="cz">
								{{i18n('129')}}
							</div>
						</div>
						<div :class="wxspZd?'spListInner':'spListInner spListInnerAc'">
							<spItem v-for="(item,index) in invalid" :key="index" :data='item'
								@checkedChange="checkedChange(index,4)"></spItem>
							<div class="zdTxt" v-if="invalid.length>5" @click="wxspZd=!wxspZd">
								{{i18n('130')}}{{invalid.length-5}}{{i18n('131')}} 
								<img src="@/assets/view-list.png" alt="" v-if="wxspZd">
								<img src="@/assets/view-list-up.png" alt="" v-else>
							</div>
						</div>
					</div>
				</div>
				<div class="qx">
					<div class="" @click="checkedChange(-1,3)">
						<img src="@/assets/sele.png" alt="" v-if="seleAll">
						<img src="@/assets/seleNo.png" alt="" v-else>
						{{i18n('125')}}
					</div>
					<div class="" @click="dele">
						{{i18n('133')}}
					</div>
				</div>
			</div>
			<div class="jsxx" v-if="type==1">
				<ddzy></ddzy>
			</div>
			<wcmList v-if="type==2"></wcmList>
		</div>
		<div class="noData" v-if="commodity.length==0 && type==1">
			<div class="">
				<img src="@/assets/noData4.png" alt="">
				<div class="text">
					{{i18n('134')}}
				</div>
			</div>
		</div>	
		<coupon ref="coupon" @couponNumber="couponNumberChange"></coupon>
		<seleAddress ref="seleAddress"></seleAddress>
		<plsc ref="plsc"></plsc>
	</div>
</template>

<script>
	import {
		getCookies
	} from "@/utils/cookies.js"
	import {
		addressDefaultV2
	} from "@/api/address.js"

	import tab from "./components/tab.vue"
	import coupon from "./components/coupon.vue"
	import spItem from "./components/spItem.vue"
	import plsc from "./components/plsc.vue"
	import ddzy from "./components/ddzy.vue"
	import seleAddress from "@/components/seleAddress/index.vue"
	import wcmList from "./components/wcmList.vue"
	export default {
		name: "cart",
		components: {
			tab,
			coupon,
			spItem,
			plsc,
			ddzy,
			seleAddress,
			wcmList
		},
		data() {
			return {
				jspsZd: true,
				ydspZd: true,
				wxspZd: true,
				couponNumber: 0,
				deliveryData: {
					money: 0,
					freight: 0
				},
				type: 1 //1购物车   2我常买
			}
		},
		methods: {
			dele() {
				this.$refs.plsc.open()
			},
			checkedChange(index, type) {
				//index =  -1 全选切换  
				if (index == -1 || index == -2) {
					if (type == 1) {
						//index=-1 全选  index=-2取消全选
						!this.forthwithChecked ? index = -1 : index = -2;
					} else if (type == 2) {
						!this.reserveChecked ? index = -1 : index = -2;
					} else if (type == 4) {
						!this.invalidChecked ? index = -1 : index = -2;
					} else if (type == 3) {
						!this.seleAll ? index = -1 : index = -2;
					}
				}
				this.$store.dispatch('cart/updateSele', {
					index,
					delivery_modeint: type
				})
			},
			couponNumberChange(number) {
				this.couponNumber = number
			},
			typeChange(type) {
				this.type = type
			},
			couponOpen() {
				this.$refs.coupon.open()
			},
			seleAddressOpen() {
				this.$refs.seleAddress.open()
			},
			//获取默认地址
			async addressDefault() {
				let {
					data
				} = await addressDefaultV2()
				this.$store.dispatch('serviceArea/updateAddressData', data)
			},
		},
		computed: {
			seleAll() {
				let data = true
				let commodity = this.$store.state.cart.commodity
				commodity.map(item => {
					if (!item.checked) {
						data = false
					}
				})
				return data
			},
			commodity() {
				return this.$store.state.cart.commodity
			},
			forthwith() {
				console.log(this.$store.state.cart.forthwith)
				return this.$store.state.cart.forthwith
			},
			forthwithChecked() {
				let data = true
				let forthwith = this.$store.state.cart.forthwith
				forthwith.map(item => {
					if (!item.checked) {
						data = false
					}
				})
				return data
			},
			reserve() {
				return this.$store.state.cart.reserve
			},
			reserveChecked() {
				let data = true
				let reserve = this.$store.state.cart.reserve
				reserve.map(item => {
					if (!item.checked) {
						data = false
					}
				})
				return data
			},
			invalid() {
				return this.$store.state.cart.invalid
			},
			invalidChecked() {
				let data = true
				let invalid = this.$store.state.cart.invalid
				invalid.map(item => {
					if (!item.checked) {
						data = false
					}
				})
				return data
			},
			addressData() {
				let addressData = JSON.parse(JSON.stringify(this.$store.state.serviceArea.addressData))
				if (addressData) {
					addressData.phone = addressData.phone.substring(2)
				}
				return addressData
			},
		},
		// watch: {
		// 	addressData() {
		// 		this.cityDelivery()
		// 	}
		// },
		created() {
			this.$store.commit('user/setClassifyShow', false)
			if (!this.$store.state.serviceArea.addressData && getCookies('token')) {
				this.addressDefault()
			}
		},
		mounted() {
			if(this.$store.state.user.token && this.$store.state.cart.commodity.length==0){
				this.$store.dispatch('cart/cartAll')
			}
		}
	};
</script>

<style scoped>
	.noData{
		width: 100%;
		height: 290px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		margin-top: 24px;
	}
	.qx {
		width: 100%;
		height: 70px;
		background: #FFFFFF;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 24px;
		box-sizing: border-box;
	}

	.qx div {
		cursor: pointer;
	}

	.qx div:nth-child(1) {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 500;
	}

	.qx div:nth-child(1) img {
		width: 24px;
		height: 24px;
		margin-right: 17px;
		display: block;
	}

	.qx div:nth-child(2) {
		padding: 0 10px;
		height: 32px;
		background: #56AB7B;
		border-radius: 16px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 32px;
		font-weight: 500;
	}

	.zdTxt {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 10;
		background: #fff;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		cursor: pointer;
	}

	.zdTxt img {
		width: 16px;
		height: 16px;
		opacity: 0.4;
		display: block;
	}

	.spListInner {
		max-height: 516px;
		overflow: hidden;
		position: relative;
		padding-bottom: 50px;
		box-sizing: border-box;
	}

	.spListInnerAc {
		max-height: none;
	}

	.spList {
		width: 827px;
		margin: 0 auto;
	}

	.spList .listHeader {
		display: flex;
		align-items: center;
		height: 48px;
		border-bottom: 1px solid #e8e8e8;
	}

	.spList .listHeader .sele {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
		width: 136px;
	}

	.spList .listHeader .sele img {
		width: 24px;
		height: 24px;
		display: block;
		margin-right: 8px;
	}

	.spList .listHeader .name,
	.spList .listHeader .dj,
	.spList .listHeader .sl,
	.spList .listHeader .xj,
	.spList .listHeader .cz {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
	}

	.spList .listHeader .name {
		width: 214px;
	}

	.spList .listHeader .dj {
		width: 138px;
		text-align: center;
	}

	.spList .listHeader .sl {
		width: 144px;
		text-align: center;
	}

	.spList .listHeader .xj {
		width: 145px;
		text-align: center;
	}

	.spList .listHeader .cz {
		flex: 1;
		text-align: right;
	}

	.ps {
		width: 875px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 16px;
		overflow: hidden;
	}

	.wxsp {
		background: #A0A9A4;
	}

	.ps .tit {
		width: 100%;
		height: 48px;
		background: #56AB7B;
		box-shadow: 0px 1px 0px 0px rgba(232, 232, 232, 1);
		padding-left: 22px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 400;
	}

	.ps .tit .spType {
		height: 24px;
		border: 1px solid rgba(255, 255, 255, 1);
		border-radius: 2px;
		line-height: 24px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 400;
		padding: 0 7px;
		margin-right: 16px;
	}

	.seleDz {
		width: 100%;
		height: 80px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 16px 20px 16px 13px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.seleDz .left {
		display: flex;
	}

	.seleDz .left img {
		width: 20px;
		height: 20px;
		display: block;
		margin-right: 8px;
	}

	.seleDz .left .addressInfo .xx {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 14px;
		font-weight: 500;
		height: 27px;
		padding-top: 3px;
		width: 650px;
	}

	.seleDz .left .addressInfo .user {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #1D2129;
		font-weight: 400;
		opacity: .6;
	}

	.seleDz .left .addressInfo .user span {
		padding-left: 5px;
	}

	.yhq {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 70px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 16px;
		padding: 0 20px 0 13px;
		box-sizing: border-box;
	}

	.yhq .left {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		display: flex;
		align-items: center;
	}

	.yhq .left img {
		width: 20.9px;
		height: 20.9px;
		display: block;
		margin-right: 8px;
	}

	.yhq .right,
	.seleDz .right {
		height: 32px;
		background: #FFFFFF;
		border: 1px solid rgba(86, 171, 123, 1);
		border-radius: 16px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		line-height: 32px;
		font-weight: 500;
		padding: 0 22px;
		cursor: pointer;
	}

	.spXx {
		width: 875px;
		height: 100%;
		overflow-y: scroll;
		margin-right: 24px;
	}

	.inner {
		flex: 1;
		display: flex;
		overflow-y: scroll;
	}

	.cart {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 50px;
		background: #F5F5F5;
	}
</style>
