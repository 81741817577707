<template>
	<div class="spItem">
		<div class="sele" @click="checkedChange">
			<img src="@/assets/sele.png" alt="" v-if="data.checked">
			<img src="@/assets/seleNo.png" alt="" v-else>
		</div>
		<div class="zt">
			<img :src="data.base.cover" alt="" class="bg">
			<view class="sx" v-if="type==2">
				<img src="@/assets/bh.png">
			</view>
		</div>
		<div class="info">
			<div class="name over2">
				{{lang=='zh'?data.base.zh_name:lang=='en'?data.base.en_name:data.base.vi_name}}
			</div>
			<div class="gg">
				{{lang=='zh'?data.skuAc.zh_name:lang=='en'?data.skuAc.en_name:data.skuAc.vi_name}}
			</div>
		</div>
		<div class="dj">
			<span>₱</span>{{data.skuAc.price}}
		</div>
		<div class="jjcz" v-if="type!=2">
			<div class="jian" @click="addCart(2)">
				<img src="@/assets/jian.png" alt="">
			</div>
			<div class="num">
				<input type="number" v-model="Count" @focus="numFocus" @blur="numConfirm">
			</div>
			<div class="jia" @click="addCart(1)">
				<img src="@/assets/jia.png" alt="">
			</div>
		</div>
		<div class="jjcz" v-if="type==2">
			{{Count}}
		</div>
		<div class="xj">
			<span>₱</span>{{data.skuAc.price*Count}}
		</div>
		<div class="cz">
			<img src="@/assets/dele.png" alt="" @click="confirmDel">
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		name: "spItem",
		components: {

		},
		data() {
			return {
				loding: false,
				addCartNumber: 0,
				Count: 0
			}
		},
		methods: {
			checkedChange() {
				if (this.type == 1) {
					this.$emit('checkedChange')
				}
			},
			numFocus() {
				this.old = JSON.parse(JSON.stringify(this.Count)) 
			},
			numConfirm() {
				let nimber = JSON.parse(JSON.stringify(this.Count)) 
				this.addCart(3, nimber)
			},
			confirmDel() {
				//删除 当前购物车宝贝
				this.$store.dispatch("cart/cartDelete", {
					ids: [this.data.CartId]
				})
			},
			//添加购物车
			async addCart(type, inputNumber) {
				if (!this.$store.state.user.token) {
					this.$router.push('/loginRegistration?type=1')
					return false
				}
				if (this.loding) {
					return false
				}
				this.addCartNumber = Number(this.data.Count)
				let number = 0
				if (type == 1) {
					number = this.addCartNumber + 1
				} else if (type == 2) {
					if (this.addCartNumber > 1) {
						number = this.addCartNumber - 1
					} else {

						number = 0

					}
				} else {
					number = inputNumber
				}
				if (number < 1) {
					//弹窗提示删除
					// this.$refs.twoTimeConfirm.open()
					if (type == 3) {
						this.Count = this.old
					}
					return false
				}
				let stock = 0
				if (this.data.skuAc.stock_type == 0) {
					stock = parseInt(this.data.base.stock / this.data.skuAc.quantity)
				} else {
					stock = parseInt(this.data.base.stock / (this.data.skuAc.quantity * this.data
						.unit_info.proportion))
				}
				// 直接 满足 库存就加入
				if (number > stock) {
					this.$message.error(this.i18n('81'))
					this.loding = false
					if (type == 3) {
						this.Count = this.old
						console.log('this.data.Count')
						console.log(this.Count)
					}
				} else if (number > this.purchase_limit) {
					if (type == 3) {
						this.Count = this.old
					}
					this.$message.error(this.i18n('80') + this.purchase_limit)
				} else {
					this.addCartNumber = number
					let params = JSON.parse(JSON.stringify(this.data))
					params.Count = number
					await this.$store.dispatch("cart/addCart", params)
					// uni.showToast({
					// 	title: this.i18n('addedSuccessfully')
					// })
					this.loding = false
				}
			}
		},
		watch: {
			"data.Count"(newData) {
				this.Count = newData
			}
		},
		computed: {
			purchase_limit() {
				let purchase_limit = 10000000000
				if (this.data.base.purchase_limit > 0) {
					purchase_limit = this.data.base.purchase_limit
				}
				return purchase_limit
			},
			type() {
				if (this.data) {
					if (this.data.invalid == 0) {
						return 1
					} else {
						return 2
					}
				} else {
					return 1
				}
			},
			lang() {
				return this.$store.state.i18n.lang
			},
		},
		created() {

		},
		mounted() {
			this.Count = this.data.Count
		}
	};
</script>

<style scoped>
	.num {
		width: 72px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(86, 171, 123, 0.10);
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		font-weight: 400;
	}
	
	.num input {
		width: 72px;
		height: 36px;
		background: none;
		border: none;
		text-align: center;
		line-height: 36px;
	}
	
	.jian,
	.jia {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.jian img,
	.jia img {
		width: 16px;
		height: 16px;
		opacity: 0.5;
		display: block;
	}
	
	.jian {
		border-right: 1px solid rgba(193, 193, 193, 1);
	}
	
	.jia {
		border-left: 1px solid rgba(193, 193, 193, 1);
	}
	
	.jjcz {
		width: 144px;
		height: 36px;
		background: #FFFFFF;
		border: 1px solid rgba(193, 193, 193, 1);
		border-radius: 4px;
		display: flex;
		font-family: Helvetica;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.spItem {
		width: 100%;
		padding: 10px 0;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		align-items: center;
	}

	.spItem .sele {
		width: 24px;
		height: 24px;
		margin-right: 20px;
		cursor: pointer;
	}

	.spItem .sele img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.spItem .zt {
		position: relative;
		width: 72px;
		height: 72px;
		border-radius: 3.43px;
		margin-right: 20px;
	}

	.spItem .zt .bg {
		width: 100%;
		height: 100%;
		display: block;
	}

	.spItem .zt .sx {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		border-radius: 3.43px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.30);
	}

	.spItem .zt .sx img {
		width: 60.95px;
		height: 48px;
		display: block;
	}
	.spItem .info{
		width: 214px;
		overflow: hidden;
	}
	.spItem .info .name{
		height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.spItem .info .gg{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.spItem .dj{
		width: 138px;
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 20px;
		font-weight: 400;
	}
	.spItem .xj{
		width: 145px;
		text-align: center;
	}
	.spItem .cz{
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.spItem .cz img{
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;
	}
</style>
