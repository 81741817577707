<template>
	<div class="ddzy">
		<div class="tit">
			{{i18n('114')}}
		</div>
		<div class="list">
			<div class="item">
				<div class="left">
					{{i18n('115')}}：
				</div>
				<div class="right">
					<span>₱</span>{{seleItems.total_money}}
				</div>
			</div>
		</div>
		<div class="but" @click="sub">
			{{i18n('116')}}：
		</div>
	</div>
</template>

<script>
	export default {
		name: "ddzy",
		components: {
	
		},
		data() {
			return {
				
			}
		},
		computed: {
			seleItems() {
				let forthwith = this.$store.state.cart.forthwith
				let reserve = this.$store.state.cart.reserve
				let obj = {
					forthwith: [],
					reserve: [],
					total_money: 0
				}
				forthwith.map(item => {
					if (item.checked) {
						obj.forthwith.push(item)
						obj.total_money = obj.total_money + item.skuAc.price * item.Count
					}
				})
				reserve.map(item => {
					if (item.checked) {
						obj.reserve.push(item)
						obj.total_money = obj.total_money + item.skuAc.price * item.Count
					}
				})
				return obj
			},
			addressData() {
				return this.$store.state.serviceArea.addressData
			}
		},
		methods: {
			sub(){
				if (this.$store.state.user.token) {
					if(!this.addressData){
						this.$message.error(this.i18n('4043'))
						return false
					}
					
					if(this.seleItems.forthwith.length+this.seleItems.reserve.length>0){
						this.$store.commit('cart/setSetItems', this.seleItems)
						if(this.addressData.latitude==0){
							this.$router.push('/order')
						}else{
							this.$router.push('/orderNew')
						}
					} 
					
					
				} else {
					this.$router.push('/loginRegistration?type=1')
				}
				
			}
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.but{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		margin-top: 30px;
		cursor: pointer;
	}
	.list{
		width: 241px;
		margin: 0 auto;
		padding: 12px 0;
		border-bottom: 1px solid #e8e8e8;
	}
	.item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
	}
	.item .left{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.item .right{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F23725;
		text-align: right;
		line-height: 28px;
		font-weight: 400;
	}
	.item .right span{
		font-size: 12px;
	}
	.ddzy{
		width: 289px;
		background: #FFFFFF;
		border-radius: 9px;
		padding-bottom: 20px;
	}
	.tit{
		width: 100%;
		padding-left: 24px;
		height: 48px;
		line-height: 48px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		border-bottom: 1px solid #56AB7B;
		box-sizing: border-box;
	}
</style>