<template>
	<div class="tab">
		<div :class="type==1?'item itemAc':'item'" @click="typeChange(1)">
			{{i18n('29')}}
		</div>
		<div :class="type==2?'item itemAc':'item'" @click="typeChange(2)">	
			{{i18n('118')}}
		</div>
	</div>
</template>

<script>
	export default {
		props:['type'],
		name: "tab",
		components:{
			
		},
		data() {
			return {
				
			}
		},
		methods: {
			typeChange(type){
				this.$emit("typeChange",type)
			},
		},
		computed:{
			
		},
		created(){
			
		},
		mounted(){
			
		}
	};
</script>

<style scoped>
	.tab{
		height: 73px;
		display: flex;
		align-items: center;
	}
	.tab .item{
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #4E5969;
		line-height: 30px;
		font-weight: 500;
		margin-right: 40px;
		border-bottom: 3px solid #F5F5F5;
		cursor: pointer;
	}
	.tab .itemAc{
		color: #56AB7B;
		border-bottom: 3px solid #56AB7B;
	}
</style>