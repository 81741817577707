<template>
	<div class="yhItem">
		<div class="one">
			<div class="left">
				<div class="jg">
					{{data.coupon.amount}}<span>₱</span>
				</div>
				<div class="sm" v-if="data.coupon.min_point==0">
					{{i18n('88')}}
				</div>
				<div class="sm" v-else>
					{{i18n('89')}}{{data.coupon.min_point}}{{i18n('90')}}{{data.coupon.amount}}
				</div>
			</div>
			<div class="right">
				<div class="tit">
					<div class="">
						{{lang=='zh'?data.coupon.zh_name:lang=='en'?data.coupon.en_name:data.coupon.vi_name}}
					</div>
					<div class="sy" v-if="type==1">
						{{i18n('91')}}
					</div>
					<div class="sx" v-if="type==2">
						{{i18n('92')}}
					</div>
				</div>
				<div class="bot">
					<div class="sj">
						<div class="gq" v-if="time<7 && time>0">
							{{i18n('93')}}
						</div>
						{{time>0?time+i18n('95'):i18n('94')}}
					</div>
					<div class="cz" @click="zk=!zk">
						{{!zk?i18n('96'):i18n('97')}} <img src="@/assets/view-list.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="two" v-if="zk">
			{{lang=='zh'?data.coupon.zh_note:lang=='en'?data.coupon.en_note:data.coupon.vi_note}}
		</div>
	</div>
</template>

<script>
	export default {
		name:"yhItem",
		props: ['type', 'data'],
		data() {
			return {
				zk: false
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			time() {
				let newDate = (new Date()).valueOf() / 1000;
				if (this.data.coupon.valid_type == 0) {
					console.log(parseInt((this.data.coupon.end_time - newDate) / 86400))
					return parseInt((this.data.coupon.end_time - newDate) / 86400)
				} else {
					// 领取时间 + 有效时间-当前时间
					console.log(parseInt((this.data.history.create_time + this.data.coupon.valid_days - newDate) / 86400))
					return parseInt((this.data.history.create_time + this.data.coupon.valid_days - newDate) / 86400)
				}
			}
		},
		mounted() {

		},
		methods: {
			syClick(type) {
				if (type == 0) {
					//使用类型：0->全场通用；1->指定分类；2->指定商品，3->指定区域
					this.switchTab('/pages/tabBar/categories/categories')
				} else if (type == 1) {
					if (this.data.categoryes.length == 1) {
						console.log("this.data.categoryes")
						console.log(this.data.categoryes)
						console.log(this.data.categoryes[0].category_id)
						this.$store.commit("user/setSeleCategory", {
							childrenId: this.data.categoryes[0].category_id
						})
					} else {
						this.$store.commit("user/setSeleCategory", {
							id: this.data.categoryes[0].category_id,
							childrenId: this.data.categoryes[1].category_id
						})
					}

					this.switchTab('/pages/tabBar/categories/categories')
				} else if (type == 2) {
					this.navigateTo('/pages/goodsDetails/index?id=' + this.data.commodityes[0].commodity_id)
				}
			}
		}
	}
</script>

<style scoped>
	.yhItem{
		width: 428px;
	}
	.two{
		padding: 24px 16px 14px 16px;
		margin-top: -10px;
		background: rgba(86, 171, 123, 0.10);
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		line-height: 20px;
		font-weight: 400;
		border-radius: 6px;
	}
	.one{
		display: flex;
		position: relative;
		overflow: hidden;
		z-index: 20;
	}
	.one::after{
		content: "";
		width: 10px;
		height: 10px;
		border: 1px solid rgba(86,171,123,0.5);
		border-radius: 50%;
		position: absolute;
		left: 92px;
		top: -5px;
		background: #fff;
	}
	.one::before{
		content: "";
		width: 10px;
		height: 10px;
		border: 1px solid rgba(86,171,123,0.5);
		border-radius: 50%;
		position: absolute;
		left: 91px;
		bottom: -5px;
		background: #fff;
	}
	.one .left{
		width: 97px;
		height: 90px;
		background-image: linear-gradient(180deg, #B5D58F 0%, #56AB7B 100%);
		border-radius: 6px 0px 0px 6px;
	}
	.one .left .jg{
		font-family: PingFangSC-Medium;
		font-size: 40px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		font-weight: 500;
		padding-top: 11px;
	}
	.one .left .jg span{
		font-family: Helvetica;
		font-size: 12px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		font-weight: 400;
	}
	.one .left .sm{
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 400;
	}
	.one .right{
		width: 331px;
		height: 88px;
		background: #FFFFFF;
		border: 0.5px solid rgba(86,171,123,0.5);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 0px 6px 6px 0px;
	}
	.one .right .tit{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 10px;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #121212;
		font-weight: 500;
	}
	.one .right .tit div.sx{
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #C9CDD4;
		line-height: 22px;
		font-weight: 500;
	}
	.one .right .tit div.sy{
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #56AB7B;
		line-height: 22px;
		font-weight: 500;
	}
	.one .right .bot{
		padding: 8px 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.one .right .bot .sj{
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #86909C;
		font-weight: 400;
	}
	.one .right .bot .sj .gq{
		width: 60px;
		height: 20px;
		border: 1px solid rgba(86,171,123,1);
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #56AB7B;
		font-weight: 400;
		padding: 0 6px;
		line-height: 20px;
		margin-right: 8px;
	}
	.one .right .bot .cz{
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #4E5969;
		font-weight: 400;
		cursor: pointer;
	}
	.one .right .bot .cz img{
		width: 12px;
		height: 12px;
		margin-left: 5px;
		display: block;
	}
</style>
