<template>
	<div class="wcmList" ref="scollElement">
		<div class="noData" v-if="noData">
			<div class="">
				<img src="@/assets/noData2.png" alt="">
				<div class="text">
					{{i18n('119')}}
				</div>
			</div>
		</div>
		<div class="item" v-for="(item,index) in listData" :key="index">
			<commodity :data='item'></commodity>
			<div class="dele" @click="delItem(item,index)">
				<img src="@/assets/dele.png" alt="">
			</div>
		</div>
		<div class="pagination" v-if="!noData">
			<el-pagination layout="prev, pager, next" :total="total" :page-size="params.page_size" :current-page='params.page_num'
				@current-change="currentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {
		oftenbuy,
		deleteOftenbuy
	} from "@/api/commodity.js"

	import commodity from "@/components/commodity.vue"
	export default {
		components: {
			commodity
		},
		data() {
			return {
				listData: [],
				noData: false,
				params: {
					page_num: 1,
					page_size: 20
				},
				total:0
			}
		},
		mounted() {
			this.oftenbuy()
		},
		methods: {
			currentChange(page_num){
				this.params.page_num=page_num
				this.oftenbuy()
			},
			async oftenbuy() {
				let {
					data,total
				} = await oftenbuy(this.params)
				this.total=total
				this.listData = data
				if (total==0) {
					this.noData = true
				} else {
					this.noData = false
				}
			},
			async delItem(item, index) {
				await deleteOftenbuy({
					commodity_id: item.base.id
				})
				this.listData.splice(index, 1)
			}
		}
	}
</script>

<style scoped>
	.noData {
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.noData img {
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
	}

	.noData .text {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}

	>>>.el-pager li {
		background: none;
	}

	>>>.el-pagination .btn-next {
		background: none;
	}

	>>>.el-pagination button:disabled {
		background: none;
	}

	>>>.el-pagination .btn-prev {
		background: none;
	}

	>>>.el-pager li.active {
		color: #56AB7B;
	}

	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.wcmList {
		flex: 1;
		overflow-y: scroll;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.wcmList .item {
		margin-bottom: 16px;
		margin-right: 22px;
		position: relative;
	}

	.wcmList .item:nth-child(5n) {
		margin-right: 0;
	}
	.wcmList .item .dele{
		width: 24px;
		height: 24px;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 4px;
		top: 4px;
		cursor: pointer;
	}
	.wcmList .item .dele img{
		width: 16px;
		height: 16px;
	}
</style>
